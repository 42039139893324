import request from '@/plugins/axios'

export function getMaterialList(query) {
  const params = {}
  Object.keys(query).forEach(key => {
    if (query[key] !== null && query[key] !== undefined && query[key] !== '') {
      params[key] = query[key]
    }
  })
  // 分页获取素材
  return request({
    url: 'material/materials',
    method: 'get',
    params
  })
}
export function getMaterialAll(query) {
  const params = {}
  Object.keys(query).forEach(key => {
    if (query[key] !== null && query[key] !== undefined && query[key] !== '') {
      params[key] = query[key]
    }
  })
  // 获取 全部 素材
  return request({
    url: 'material/materials:get_all',
    method: 'get',
    params
  })
}

export function deleteMaterial(id) {
  // 获取 脸书 帐号列表
  return request({
    url: `material/materials/${id}`,
    method: 'delete'
  })
}

// export function queryMaterial(data, videoStyle = null, imageStyle = null) {
//   const params = data
//   if (videoStyle !== null) {
//     params.video_style = videoStyle
//   }
//   if (imageStyle !== null) {
//     params.image_style = imageStyle
//   }

//   // 根据关键词查询素材
//   return request({
//     url: '/material/materials',
//     method: 'get',
//     params
//   })
// }

export function getMaterial(id,data) {
  // 获取素材下载链接
  return request({
    url: `material/materials/${id}`,
    method: 'get',
    params:data
  })
}

// export function getMaterialDownUrl(id) {
//   // 获取素材下载链接
//   return request({
//     url: `/materials/${id}/downloadUrl`,
//     method: 'get'
//   })
// }

export function updateMaterial(id, updateData) {
  const data = {}
  Object.keys(updateData).forEach(key => {
    if (
      updateData[key] !== null &&
      updateData[key] !== undefined &&
      updateData[key] !== ''
    ) {
      data[key] = updateData[key]
    }
  })

  // 获取 脸书 帐号列表
  return request({
    url: `/materials/${id}`,
    method: 'put',
    data
  })
}

// 素材批量删除
export function delArrApi(data) {
  return request({
    url: '/material/materials:batch_delete',
    method: 'post',
    data
  })
}
