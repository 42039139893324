<template>
  <div class="app-container">
    <el-form
      :inline="true"
      :model="queryForm"
      class="demo-form-inline account-top"
    >
      <el-form-item label="风险类型">
        <el-select
          v-model="queryForm.risk_type"
          placeholder="风险类型"
          clearable
        >
          <el-option label="无风险" value="0"></el-option>
          <el-option label="直接发送原文" value="1"></el-option>
          <el-option label="转为图片发送" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发贴帐号">
        <el-input
          v-model="queryForm.account"
          placeholder="发帖帐号id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="任务状态">
        <el-select v-model="queryForm.status" placeholder="任务状态" clearable>
          <el-option label="完成" value="1"></el-option>
          <el-option label="待执行" value="0"></el-option>
          <el-option label="执行失败" value="-1"></el-option>
          <el-option label="帐号失效" value="-11"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发帖用户" v-if="userInfo.is_staff">
        <el-input
          v-model="queryForm.user"
          placeholder="发帖用户id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="搜索">
        <el-input
          v-model="queryForm.search"
          placeholder="文章内容"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
      <el-button type="primary" @click="fetchData" v-if="!userInfo.is_staff">
        刷新
      </el-button>
      <el-button type="danger" @click="batchDel">批量删除</el-button>
    </el-form>
    <!-- <div style="text-align: right;padding: 0px 5px 5px 5px"></div> -->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      class="tableList2"
    >
      <el-table-column type="selection" width="30"></el-table-column>
      <el-table-column prop="id" label="ID" width="100"></el-table-column>
      <el-table-column
        prop="content"
        label="帖子内容"
        show-overflow-tooltip
      ></el-table-column>
      <!-- <el-table-column prop="account_id" label="发帖帐号ID"></el-table-column> -->
      <el-table-column prop="account_entity" label="发帖帐号">
        <template slot-scope="scope">
          {{
            scope.row.account_entity
              ? '@' + scope.row.account_entity.screen_name
              : null
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="publish_time"
        label="设定发帖时间"
      ></el-table-column>
      <!-- <el-table-column
        prop="media"
        label="多媒体链接"
        show-overflow-tooltip
      ></el-table-column> -->
      <el-table-column prop="media_entity" label="发帖素材">
        <template slot-scope="scope">
          {{ scope.row.media_entity ? scope.row.media_entity.name : null }}
        </template>
      </el-table-column>
      <el-table-column prop="tweet_url" label="推文链接">
        <!-- 点击复制 -->
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            content="点击复制"
            placement="top"
          >
            <div
              class="tag-read"
              style="cursor:pointer;"
              @click="copy(scope.row.tweet_url)"
              :data-clipboard-text="copeValue"
            >
              {{ scope.row.tweet_url }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="getStatusTip(scope.row.status, statusMap)"
            placement="top"
          >
            <el-tag
              :type="getStatusType(scope.row.status, statusMap)"
              disable-transitions
            >
              {{ getStatusLabel(scope.row.status, statusMap) }}
            </el-tag>
          </el-tooltip>
          <el-button
            v-if="scope.row.status === -7"
            type="primary"
            size="small"
            style="margin-left: 10px"
            @click="handleConfirmDialogShow(scope.row.id)"
          >
            确认类型
          </el-button>
          <el-button
            v-if="scope.row.status === 1 && scope.row.screenshot_entity"
            type="primary"
            size="small"
            style="margin-left: 10px"
            @click="downScreenshot(scope.row.screenshot_entity.id)"
          >
            下载截图
          </el-button>
        </template>
      </el-table-column>
      <el-table-column prop="risk_type" label="风险类型">
        <template slot-scope="scope">
          {{ risk_type[scope.row.risk_type] }}
        </template>
      </el-table-column>
      <el-table-column label="创建人" v-if="isManager">
        <template slot-scope="scope">
          {{
            scope.row.user_entity ? scope.row.user_entity.oauth_account : null
          }}
        </template>
      </el-table-column>
      <el-table-column label="创建人备注" v-if="isManager">
        <template slot-scope="scope">
          {{ scope.row.user_entity ? scope.row.user_entity.note : null }}
        </template>
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="任务创建时间"
      ></el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="queryForm.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="count"
    ></el-pagination>
    <el-dialog title="提示" :visible.sync="confirmFormDialogShow" width="30%">
      <span>
        <el-tooltip
          content="忽视帐号被封禁的风险,继续使用文字发表。此选项会使发帖帐号变为风险状态,若后续账号多次被封禁，视情况不再免费补发账号"
          placement="top"
        >
          <el-radio v-model="confirmRiskType" label="1">
            直接发表内容
          </el-radio>
        </el-tooltip>
        <el-tooltip
          content="规避帐号被封禁的风险,将文字内容转为一张图片发送。此选项不会影响发帖帐号的风险状态。"
          placement="top"
        >
          <el-radio v-model="confirmRiskType" label="2">
            转为图片发表
          </el-radio>
        </el-tooltip>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmFormDialogShow = false">取 消</el-button>
        <el-button type="primary" @click="handleConfirmRiskType">
          确 定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="用户详情"
      :visible.sync="userEntityDialogShow"
      width="30%"
    >
      <el-descriptions
        title="垂直带边框列表"
        direction="vertical"
        :column="4"
        border
      >
        <el-descriptions-item label="用户名">kooriookami</el-descriptions-item>
        <el-descriptions-item label="手机号">18100000000</el-descriptions-item>
        <el-descriptions-item label="居住地" :span="2">
          苏州市
        </el-descriptions-item>
        <el-descriptions-item label="备注">
          <el-tag size="small">学校</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="联系地址">
          江苏省苏州市吴中区吴中大道 1188 号
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import {
  delPostTaskByIds,
  getPostTaskList,
  confirmPostTaskRiskType
} from '@/api/twitter'

import { getMaterial } from '@/api/material'
import { getStatusType, getStatusLabel, getStatusTip } from '@/utils/status'
import { getManagerStatus } from '@/utils/manager'
import Clipboard from 'clipboard'

export default {
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      multipleSelection: [],
      queryForm: {
        pageIndex: 1,
        pageSize: 10,
        risk_type: '',
        account: '',
        status: '',
        user: '',
        search: ''
      },
      // pagination: {
      //   pageIndex: 1,
      //   pageSize: 10
      // },
      isManager: getManagerStatus(),
      copeValue: 'value',
      statusMap: {
        1: {
          type: 'success',
          label: '执行成功',
          tip: '任务成功发表'
        },
        0: {
          type: 'primary',
          label: '待执行'
        },
        '-1': {
          type: 'danger',
          label: '执行失败',
          tip: '任务执行失败，该任务将由人工介入处理'
        },
        '-2': {
          type: 'danger',
          label: '多媒体链接错误',
          tip: '多媒体链接无法打开或者无法下载，请尝试使用本地视频上传后发表'
        },
        '-3': {
          type: 'danger',
          label: '多媒体链接失效',
          tip: '多媒体链接无法打开或者无法下载，请尝试使用本地视频上传后发表'
        },
        '-4': {
          type: 'danger',
          label: '重复的推文',
          tip:
            '该twitter帐号近期已发表过该推文，请修改发表的推文内容后重新提交任务'
        },
        '-5': {
          type: 'danger',
          label: '视频不符合要求',
          tip:
            '视频不符合twitter上传要求，详情请查阅twitter规则说明：https://help.twitter.com/en/using-twitter/twitter-videos'
        },
        '-6': {
          type: 'danger',
          label: '推文超长',
          tip:
            'twitter限制推文长度为280字符，详情请查阅twitter规则说明：https://help.twitter.com/en/using-twitter/how-to-tweet'
        },
        '-7': {
          type: 'warning',
          label: '风险任务',
          tip: '请手动确认风险任务类型'
        },
        '-10': {
          type: 'danger',
          label: '已删除'
        },
        '-11': {
          type: 'danger',
          label: '帐号失效',
          tip: '该twitter帐号已失效，请更换twitter帐号重试'
        }
      },
      risk_type: {
        0: '无风险',
        1: '高风险',
        2: '图片任务'
      },
      confirmRiskType: 0,
      confirmRiskTaskId: '',
      confirmFormDialogShow: false,
      userEntity: {},
      userEntityDialogShow: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    getStatusType,
    getStatusLabel,
    getStatusTip,
    async fetchData() {
      this.listLoading = true
      const { all_count: count, items } = await getPostTaskList(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.pageIndex = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.pageSize = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.pageIndex = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    handleSelectionChange(items) {
      this.multipleSelection = []
      items.forEach(item => {
        if (this.multipleSelection.indexOf(item.id) === -1) {
          this.multipleSelection.push(item.id)
        }
      })
    },
    async downScreenshot(id) {
      // const url = await getPostTaskScreenshot(id)
      const { signature_url: signature_url } = await getMaterial(id)
      window.location.href = signature_url
    },
    async batchDel() {
      if (this.multipleSelection.length < 1) {
        this.$message.error('请至少选择一项需要删除的数据')
      } else {
        this.$confirm('此操作将删除该任务, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            await delPostTaskByIds(this.multipleSelection).then(resp => {
              this.fetchData()
              // this.$message({
              //   type: 'success',
              //   message: '删除成功!'
              // })
            })
          })
          .catch(() => {})
      }
    },
    // 点击复制
    copy(value) {
      const that = this
      that.copeValue = value
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        this.$message.success('复制成功！')
        //  释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        this.$message.error('该浏览器不支持复制!')
        // 释放内存
        clipboard.destroy()
      })
    },
    handleConfirmDialogShow(id) {
      this.confirmRiskTaskId = id
      this.confirmFormDialogShow = true
    },
    async handleConfirmRiskType() {
      if (this.confirmRiskType !== 0) {
        await confirmPostTaskRiskType(
          this.confirmRiskTaskId,
          this.confirmRiskType
        )
        this.confirmFormDialogShow = false
        this.confirmRiskTaskId = ''
        this.confirmRiskType = 0
      } else {
        this.$message.warning('请选择任务类型')
      }
    }
  }
}
</script>
